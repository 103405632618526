import CSFlagsSelect from "@components/Form/CSFlagsSelect"
import CSInput from "@components/Form/CSInput"
import { CSOption } from "@components/Form/CSReactSelect"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { CustomerShippingInfo } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { checkForm, FormErrors } from "@utils/FormUtils"
import { PageStates } from "@utils/PageStateUtils"
import { useState } from "react"
import { Modal } from "react-bootstrap"

type Props = {
    shippingInfo?: CustomerShippingInfo
    isEditable: boolean
    show: boolean
    onHide: ()=>unknown
    onUpdate: (updatedShippingInfo: CustomerShippingInfo) => unknown
}

const EditCustomerShippingInfo = (props: Props) =>{
    const { shippingInfo, isEditable, show, onHide, onUpdate } = props
    const [editShippingInfo, setEditShippingInfo] = useState<CustomerShippingInfo>(shippingInfo ?? {})
    const [formErrors, setFormErrors] = useState<FormErrors>({})
    const [componentState, setComponentState] = useState<PageStates>(PageStates.IDLE)

    const { alias, first_name, last_name, company_name, address={}, contacts={}, is_default, ext_ids } = editShippingInfo
    const { address_lines=[], city, country_code, postal_code, province, province_code } = address
    const { email, phone, mobile  } = contacts
    const address_1 = address_lines?.[0]
    const address_2 = address_lines?.[1]

    const nominee = (first_name && last_name) ? `${first_name} ${last_name}`: company_name

    let modalTitle: string|undefined = Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.AGGIUNGI_INDIRIZZO
    if(!isEditable) {
        modalTitle = alias ?? nominee
    }
    if(isEditable && shippingInfo) {
        modalTitle = Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.MODIFICA_INDIRIZZO
    }

    const changeValue = (value: any, field: string) =>{
        return setEditShippingInfo(prev=>{
            const updatedShippingInfo = {
                ...prev,
                [field]: value
            }
    
            return updatedShippingInfo
        })
    }

    const changeAddressValue = (value: any, field: string) =>{
        return setEditShippingInfo(prev=>{
            const updatedAddress = {
                ...prev.address,
                [field]: value
            }

            const updatedShippingInfo = {
                ...prev,
                address: updatedAddress
            }
    
            return updatedShippingInfo
        })
    }

    const changeContactsValue = (value: any, field: string) =>{
        return setEditShippingInfo(prev=>{
            const updatedContacts = {
                ...prev.contacts,
                [field]: value
            }

            const updatedShippingInfo = {
                ...prev,
                contacts: updatedContacts
            }
    
            return updatedShippingInfo
        })
    }

    const changeAddressLine = (value: string, index: number) =>{
        return setEditShippingInfo(prev=>{
            const updatedAddressLines = [...address_lines ?? []]
            updatedAddressLines[index] = value
    
            const updatedAddress = {
                ...prev.address,
                address_lines: updatedAddressLines
            }
    
            const updatedShippingInfo = {
                ...prev,
                address: updatedAddress
            }
    
            return updatedShippingInfo

        })
    }

    const checkAliasFunction = ()=>{
        if(company_name || (first_name && last_name)) {
            return undefined
        }

        return Localization.PAGE_CUSTOMERS.ERRORE_NOMINATIVO
    }

    const updateShippingInfo = ()=>{
        // check form fields for errors
        setComponentState(PageStates.IDLE)
        const response = checkForm([
            {id: "alias", value: alias?.trim(), required:true},
            {id: "first_name", value: first_name?.trim(), customCheckFunction: checkAliasFunction},
            {id: "last_name", value: last_name?.trim(), customCheckFunction: checkAliasFunction},
            {id: "company_name", value: company_name?.trim(), customCheckFunction: checkAliasFunction},
            {id: "country_code", value: country_code, required:true},
            {id: "address_1", value: address_lines?.[0]?.trim(), required:true, boundaries: {min: 1, max: 255}},
            {id: "address_2", value: address_lines?.[1]?.trim(), boundaries: {min: 1, max: 255}},
            {id: "city", value: city?.trim(), required:true, boundaries: {min: 1, max: 100}},
            {id: "province", value: province?.trim(), required:true, boundaries: {min: 1, max: 100}},
            {id: "province_code", value: province_code?.trim(), required:true},
            {id: "postal_code", value: postal_code?.trim(), required:true, boundaries: {min: 1, max: 16}},
            {id: "email", value: email?.trim(), required: true, regex: "^[\\w.-]+@[\\w.-]+\\.[A-Za-z]{2,}$"},
            {id: "mobile", value: mobile?.trim(), required: true, boundaries: {min: 0, max: 16}, regex: {expression: "^\\+[1-9]\\d{1,14}$", message: Localization.PAGE_CUSTOMERS.ERRORE_TELEFONO}},
            {id: "phone", value: phone?.trim(), boundaries: {min: 0, max: 16}, regex: {expression: "^\\+[1-9]\\d{1,14}$", message: Localization.PAGE_CUSTOMERS.ERRORE_TELEFONO}},
        ])

        if(Object.keys(response).length>0) {
            setFormErrors(response)
            return setComponentState(PageStates.FORM_ERROR)
        }

        // fix to avoid empty strings in mandatory fields
        const editedShippingInfo = structuredClone(editShippingInfo)
        if(editedShippingInfo.first_name === "") { editedShippingInfo.first_name = undefined }
        if(editedShippingInfo.last_name === "") { editedShippingInfo.last_name = undefined }
        if(editedShippingInfo.company_name === "") { editedShippingInfo.company_name = undefined }

        onHide()
        return onUpdate(editedShippingInfo)

    }

    const countryOptionSelected = country_code ? {
        value: country_code,
        label: Localization.COUNTRIES_CODE[country_code as keyof typeof Localization.COUNTRIES_CODE] 
    }: undefined

    return <Modal size="xl" show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SectionSubGroup title={Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.INFORMAZIONI_GENERALI}>
                <div className="row">
                    <div className="col-md-12">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.NOME}`} type="text" controlId="first_name" 
                            value={first_name ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'first_name')} 
                            width={'100%'}
                            tabIndex={1}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.first_name}
                            errorText={formErrors.first_name}
                        />
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.RAGIONE_SOCIALE}`} type="text" controlId="company_name" 
                            value={company_name ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'company_name')} 
                            width={'100%'}
                            tabIndex={3}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.company_name}
                            errorText={formErrors.company_name}
                        />
                    </div>
                    <div className="col-md-12">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.COGNOME}`} type="text" controlId="last_name" 
                            value={last_name ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'last_name')} 
                            width={'100%'}
                            tabIndex={2}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.last_name}
                            errorText={formErrors.last_name}
                        />
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.ALIAS}`} type="text" controlId="alias" 
                            value={alias ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'alias')} 
                            width={'100%'}
                            tabIndex={4}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.alias}
                            errorText={formErrors.alias}
                        />
                    </div>
                </div>
            </SectionSubGroup>
            <SectionSubGroup title={Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.CONTATTI}>
                <div className="row">
                    <div className="col-md-12">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.EMAIL}`} type="text" controlId="email" 
                            value={email ?? ""}
                            onChange={(e)=>changeContactsValue(e.target.value, 'email')} 
                            width={'100%'}
                            tabIndex={5}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.email}
                            errorText={formErrors.email}
                        />
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.TELEFONO}`} type="text" controlId="phone" 
                            value={phone ?? ""}
                            onChange={(e)=>changeContactsValue(e.target.value, 'phone')} 
                            width={'100%'}
                            tabIndex={7}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.phone}
                            errorText={formErrors.phone}
                        />
                    </div>
                    <div className="col-md-12">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.CELLULARE}`} type="text" controlId="mobile" 
                            value={mobile ?? ""}
                            onChange={(e)=>changeContactsValue(e.target.value, 'mobile')} 
                            width={'100%'}
                            tabIndex={6}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.mobile}
                            errorText={formErrors.mobile}
                        />
                    </div>
                </div>

            </SectionSubGroup>
            <SectionSubGroup title={Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.INDIRIZZO}>
                <div className="row">
                    <div className="col-md-8">
                        <CSFlagsSelect
                            mandatory
                            controlId={`country_code`}
                            label={Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.NAZIONE}
                            mode="COUNTRY"
                            disabled={!isEditable}
                            className="mt-field"
                            tabIndex={7}
                            value={countryOptionSelected}
                            onChange={(option: CSOption) =>{changeAddressValue(option.value.toUpperCase(), 'country_code')}}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.country_code}
                            errorText={formErrors.country_code}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-24">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.INDIRIZZO_1}`} type="text" controlId="address_1" 
                            value={address_1 ?? ""}
                            onChange={(e)=>changeAddressLine(e.target.value, 0)} 
                            width={'100%'}
                            tabIndex={8}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.address_1}
                            errorText={formErrors.address_1}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-24">
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.INDIRIZZO_2}`} type="text" controlId="address_2" 
                            value={address_2 ?? ""}
                            onChange={(e)=>changeAddressLine(e.target.value, 1)} 
                            width={'100%'}
                            tabIndex={9}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.address_2}
                            errorText={formErrors.address_2}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.CITTA}`} type="text" controlId="city" 
                            value={city ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'city')} 
                            width={'100%'}
                            tabIndex={9}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.city}
                            errorText={formErrors.city}
                        />
                    </div>
                    <div className="col-md-4">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.CODICE_POSTALE}`} type="text" controlId="postal_code" 
                            value={postal_code ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'postal_code')} 
                            width={'100%'}
                            tabIndex={10}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.postal_code}
                            errorText={formErrors.postal_code}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.PROVINCIA}`} type="text" controlId="province" 
                            value={province ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'province')} 
                            width={'100%'}
                            tabIndex={11}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.province}
                            errorText={formErrors.province}
                        />
                    </div>
                    <div className="col-md-4">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.SIGLA_PROVINCIA}`} type="text" controlId="province_code" 
                            value={province_code ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'province_code')} 
                            width={'100%'}
                            tabIndex={12}
                            disabled={!isEditable}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.province_code}
                            errorText={formErrors.province_code}
                        />
                    </div>
                </div>
            </SectionSubGroup>
        </Modal.Body>
        <Modal.Footer>
            <div className="d-flex justify-content-center align-items-center w-100">
                {isEditable && <>
                    <button type="button" onClick={()=>onHide()} className='m-1 mx-2 btn btn-light'>{Localization.ANNULLA}</button>
                    <button type="button" onClick={()=>updateShippingInfo()} className='m-1 mx-2 btn btn-primary'>{Localization.CONFERMA}</button>
                </>}
                {!isEditable && <button type="button" onClick={()=>onHide()} className='m-1 mx-2 btn btn-light'>{Localization.CHIUDI}</button>}
            </div>
        </Modal.Footer>
    </Modal>
}

export default EditCustomerShippingInfo