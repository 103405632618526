import { getCustomerOrders } from "@api/Orders"
import FlagsIcon from "@components/FlagsIcon/FlagsIcon"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { Customer, EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { CurrentFilters } from "@utils/AdvancedFiltersUtils"
import { getCustomerNominee } from "@utils/CustomerUtils"
import { PageStates } from "@utils/PageStateUtils"
import { subYears } from "date-fns"
import { useEffect, useState } from "react"
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"

type Props = {
    customer: Customer
    isInboundCustomersEnabled: boolean
}

const CustomerHeader = (props: Props) =>{
    const [componentState, setComponentState] = useState<PageStates>(PageStates.INIT)
    const [ordersEntityResponse, setOrdersEntityResponse] = useState<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo>()
    const { customer, isInboundCustomersEnabled } = props
    const { first_name, last_name, company_name, security={}, billing_info={}, status  } = customer
    const { username } = security

    // in the title we set name if is present, else company name
    const name = (first_name && last_name)?`${first_name} ${last_name}`:undefined
    const title = <div className="fs-3 fw-bold mb-2">{name ?? company_name}</div>
    const countryCode = billing_info.address?.country_code

    const init = async()=>{
        setComponentState(PageStates.INIT)
        const customerId = customer._id?.$oid
        if(!customerId) {
            return setComponentState(PageStates.IDLE)
        }
        
        try{
            // compose a year of search orders filter
            const today = new Date(Date.now())
            const aYearAgo = subYears(today, 1)
            const filters: CurrentFilters = [
                {
                    id: "date",
                    value: [aYearAgo, today]
                }
            ]
            const ordersResponse = await getCustomerOrders(filters, customerId, 0, 2500)
            console.log({ordersResponse})
            setOrdersEntityResponse(ordersResponse)

            return setComponentState(PageStates.IDLE)
        } catch(err) {
            console.log(err)
            setComponentState(PageStates.ERROR)
        }
        
    }

    useEffect(()=>{
        init()
    },[customer._id?.$oid])

    const totalOrdersInLastYear = ordersEntityResponse?._metadata?.total_items ?? 0
    const totalAmountOrdersInLastYear = ordersEntityResponse?.content?.reduce((prev, curr)=>{
        return prev += curr.tot_amount?.amount ?? 0
    }, 0) ?? 0


    // if title is composed with name then show company name in subtitle, else show nothing
    const subtitle = <div className="d-flex align-items-center justify-content-left">
        {name && company_name && <span className="me-3"><i className="bi-building text-muted me-2" />{company_name}</span>} 
        {username && <span className="me-3"><i className="bi-person-circle text-muted me-2" />{username}</span>}
        {countryCode && <span className="me-3"><i className="bi-geo-alt-fill text-muted me-2" />{Localization.COUNTRIES_CODE[countryCode as keyof typeof Localization.COUNTRIES_CODE]}</span>}
        {status && <span className={`me-3 ${status==='ACTIVE'?"text-success":"text-danger"}`}><i className="bi-circle-fill me-2" />{status==='ACTIVE'? Localization.PAGE_CUSTOMERS.STATUS.ACTIVE : Localization.PAGE_CUSTOMERS.STATUS.INACTIVE}</span>}
        {isInboundCustomersEnabled && <>
            <OverlayTrigger placement="top" overlay={<Tooltip style={{position:'fixed'}}>{Localization.PAGE_CUSTOMERS.NETSUITE_INBOUND_TOOLTIP}</Tooltip>}>
                <span role="button">
                    <i className="bi-circle-fill text-success me-2" />
                    <img  src={"/images/netsuite.png"} style={{width: '60px'}} alt="netsuite enabled" className="me-2" />
                </span>
            </OverlayTrigger>
        </>}
    </div>

    return <SectionGroup small>
        <div  className="d-flex align-items-start justify-content-between">
            <div>
                {title}
                {subtitle}
            </div>
            <div className="d-flex align-items-center justify-content-left">
                {componentState === PageStates.INIT && <div className="d-flex align-items-center justify-content-end">
                    <Spinner variant="primary" animation="border" style={{height: '3rem', width: '3rem'}} />
                </div>}
                {componentState === PageStates.IDLE && ordersEntityResponse && <div>
                    <div className="d-flex align-items-center justify-content-left">
                        <i className="bi-cart text-success font-2x me-3" />
                        <span className="fs-4 me-4">{totalOrdersInLastYear}</span>
                        <i className="bi-cash-stack text-success font-2x me-3" />
                        <span className="fs-4">{`${totalAmountOrdersInLastYear.toFixed(2)} €`}</span>

                    </div>
                    <div className="text-end w-100 text-gray-500">{Localization.PAGE_CUSTOMERS.ORDINI_ULTIMO_ANNO}</div>
                </div>}
            </div>
        </div>
    </SectionGroup>
}

export default CustomerHeader