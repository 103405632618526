import { Customer, CustomerStatusEnum, EntityListResponseCustomer, EntityListResponseCustomerBusinessConditionReference, EntitySingleResponseCustomer } from "@csapi/provisioning"
import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "./Utils"

interface GetCustomersParams {
    searchTerm?: string
    status?: string
    pageIndex?: number,
    pageSize?: number
    sort?: string | undefined
}

const getCustomers = async (params: GetCustomersParams) :Promise<EntityListResponseCustomer> =>{
    try {
        const { searchTerm = undefined, status = undefined, sort=undefined, pageIndex=0, pageSize=20 } = params
        const response:AxiosResponse<EntityListResponseCustomer,any> = await axios.get('api/customers', {
            params: {searchTerm, status,sort, pageIndex, pageSize}
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const createCustomer = async(customer: Customer) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseCustomer,any> = await axios.post(`api/customers`,{customer})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const updateCustomer = async(id:string, customer: Customer) =>{
    try {
        const response = await axios.put(`api/customers/${id}/`,{customer})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const updateCustomerUsername = async(id:string, username: string) =>{
    try {
        const response = await axios.put(`api/customers/${id}/update-username`,{username})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const updateCustomerStatus = async(id:string, status: string) =>{
    try {
        const response = await axios.put(`api/customers/${id}/update-status/`,{status})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}


const getCustomer = async (id:string) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseCustomer,any> = await axios.get(`api/customers/${id}`)
        return response.data
    } catch(error: any) {
        if(error.response?.status === 404) {
            return undefined
        }
        checkSessionExpired(error)
        throw error
    }
}

const getCustomerBusinessConditions = async (id:string) =>{
    try {
        const response:AxiosResponse<EntityListResponseCustomerBusinessConditionReference,any> = await axios.get(`api/customers/${id}/business-conditions`)
        return response.data
    } catch(error: any) {
        if(error.response?.status === 404) {
            return undefined
        }
        checkSessionExpired(error)
        throw error
    }
}

const resetCustomerPassword = async (username : string) => {
    try{
        const response = await axios.post('api/customers/reset-password', {username})
        return response.data

    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

export { getCustomers, createCustomer, getCustomer, updateCustomer, updateCustomerUsername, updateCustomerStatus, resetCustomerPassword, getCustomerBusinessConditions }