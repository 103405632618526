import { getBusinessCondition } from "@api/BusinessConditions"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { BusinessCondition } from "@csapi/provisioning"
import Localization from "@localization/Index"
import EditBusinessConditionHeaderDetails from "@pages/BusinessConditions/Components/EditBusinessConditionHeaderDetails"
import { getBusinessConditionStatus } from "@utils/BusinessConditionsUtils"
import { PageStates } from "@utils/PageStateUtils"
import { format, parseISO } from "date-fns"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

type Props = {
    businessCondition?: BusinessCondition
    id?: string
    placeholder?: JSX.Element
}

const CustomerBusinessCondition = (props: Props) =>{
    const { businessCondition, id, placeholder } = props
    const [componentState, setComponentState] = useState<PageStates>(PageStates.IDLE)
    const [loadedBusinessCondition, setLoadedBusinessCondition] = useState<BusinessCondition | undefined>(businessCondition)

    const navigate = useNavigate()

    const loadBusinessCondition = async()=>{
        if(businessCondition || !id) {
            return setComponentState(PageStates.IDLE)
        }

        setComponentState(PageStates.DATA_LOADING)
        const bcResponse = await getBusinessCondition(id)
        setLoadedBusinessCondition(bcResponse.content)
        setComponentState(PageStates.IDLE)

        try{

        } catch(err) {
            setComponentState(PageStates.DATA_LOADING_ERROR)
        }
    }

    // useEffect(()=>{
    //     // init()
    // },[businessCondition, id])

    // if placeholder is populated and loadedBusinessCondition is not, simply show the placeholder in small section sub group
    if(placeholder && !loadedBusinessCondition && componentState === PageStates.IDLE) {
        return <SectionSubGroup>
            <div className="cs-link d-inline-flex" role="button" onClick={()=>{
            return loadBusinessCondition()
        }}>
                {placeholder}
            </div>
        </SectionSubGroup>
    }

    const { name, last_update_dt, discount, markup, exceptions, status, tax_rate, _id } = loadedBusinessCondition ?? {}

    const bcStatus = getBusinessConditionStatus(status!)
    const bcDate = (last_update_dt?.$date)?format(parseISO(last_update_dt?.$date), 'dd/MM/yyyy HH:mm'):"N.D."

    return <>
        {componentState === PageStates.DATA_LOADING && 
        <SectionSubGroup>
            <div className="d-flex align-items-center justify-content-center mt-field w-100">
                <Spinner animation="border" variant="primary" style={{width: '40px', height: "40px"}} />
            </div>
        </SectionSubGroup>
        }
        {componentState === PageStates.DATA_LOADING_ERROR &&
        <SectionSubGroup>
            <div className="d-flex align-items-center justify-content-start mt-field w-100">
                <div className="fs-6">{Localization.ERRORE_GENERICO}</div>
            </div>
        </SectionSubGroup>
        }
        {componentState === PageStates.IDLE && loadedBusinessCondition &&
        <SectionSubGroup className={`bg-${bcStatus?.bgVariant ?? bcStatus?.variant ?? "light"}-light`}>
            <div className="w-100">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex flex-column align-items-start justify-content-start">
                            <div className="cs-link fs-4" onClick={()=>navigate(`/businessconditions/${_id?.$oid}`)}>{name}</div>
                            <div className="d-flex align-items-center justify-content-start mt-1">
                                <span className="small me-1">{`${Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.ULTIMA_MODIFICA}:`}</span>
                                <span className="fw-bold">{bcDate}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-1">
                                <span className="small me-1">{`${Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.STATO}:`}</span>
                                <span className={`fw-bold text-${bcStatus?.variant}`}>{bcStatus?.label}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex flex-column align-items-start justify-content-start">
                            <div className="d-flex align-items-center justify-content-start mt-1">
                                <span className="small me-1">{`${discount ? Localization.DISCOUNT: Localization.MARKUP}:`}</span>
                                <span className="fw-bold">{`${(discount ?? markup ?? 0)?.toFixed(2)} %`}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-1">
                                <span className="small me-1">{`${Localization.TAX_RATE}:`}</span>
                                <span className="fw-bold">{`${(tax_rate ?? 0)?.toFixed(2)} %`}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-start mt-1">
                                <span className="small me-1">{`${Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.NUMERO_ECCEZIONI}:`}</span>
                                <span className="fw-bold">{`${exceptions?.length ?? 0}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {placeholder && <div className="mt-3">{placeholder}</div>}
            </div>
        </SectionSubGroup>
        }
    </>
}

export default CustomerBusinessCondition