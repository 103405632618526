import { EntityListResponseItemAvailabilityAdjustmentViewSummary, EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo, EntitySingleResponseOrderMetadata, EntitySingleWithAdditionalInfoResponseOrderOrderAdditionalInfo, Order, OrderBillingInfo, OrderItem, OrderPackagingInfoUpdateRequest, OrderShippingInfo } from "@csapi/provisioning/api";
import { checkSessionExpired } from "@api/Utils";
import axios, { AxiosResponse } from "axios";
import { CurrentFilters } from "@utils/AdvancedFiltersUtils";
import { CSOption } from "@components/Form/CSReactSelect";
import { parseSort } from "@utils/ApiUtils";
import { CSTableServerPagination } from "@components/Table/CSTable";
import { OrderDocumentTypes } from "@utils/OrderUtils";

//TODO: change params to typed object like GetOrdersArgs
const getOrders = async (searchTerm: string, filters:CurrentFilters, pageIndex: number=0, pageSize: number=20):Promise<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo> =>{
    try {
        const date = filters.find(filter=>filter.id==="date")
        const orderStatuses = filters.find(filter=>filter.id==="status")
        const filterShops = filters.find(filter=>filter.id==="shops")
        const filterSkus = filters.find(filter=>filter.id==="sku")
        const filterDate = (date?.value?? [null, null]) as [Date|null, Date|null] 
        const dateFrom = filterDate[0]
        const dateTo = filterDate[1]
        const filterCustomer = filters.find(filter=>filter.id==="customer")

        const statuses = ((orderStatuses?.value ?? []) as CSOption[]).map(status=>status.value)
        const shopIds = ((filterShops?.value ?? []) as CSOption[]).map(shop=>shop.value)
        const skus = ((filterSkus?.value ?? []) as CSOption[]).map(sku=>sku.value)
        const customer =  ((filterCustomer?.value ) as CSOption)?.value

        const params = {
            pageIndex, 
            pageSize,
            statuses,
            dateFrom: dateFrom?.toISOString(),
            dateTo: dateTo?.toISOString(),
            shopIds,
            skus,
            searchTerm,
            buyerId: customer
        }
        const response:AxiosResponse<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo,any> = await axios.get('api/orders', {
            params
        })
        return response.data 
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const getCustomerOrders = async ( filters:CurrentFilters, buyerId:string, pageIndex: number=0, pageSize: number=20):Promise<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo> =>{
    try {
        const date = filters.find(filter=>filter.id==="date")
        const filterDate = (date?.value?? [null, null]) as [Date|null, Date|null] 
        const dateFrom = filterDate[0]
        const dateTo = filterDate[1]

        const params = {
            pageIndex, 
            pageSize,
            dateFrom: dateFrom?.toISOString(),
            dateTo: dateTo?.toISOString(),
            buyerId
        }
        const response:AxiosResponse<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo,any> = await axios.get('api/orders', {
            params
        })
        return response.data 
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const getOrder = async (id:string) =>{
    try {
        const response:AxiosResponse<EntitySingleWithAdditionalInfoResponseOrderOrderAdditionalInfo,any> = await axios.get(`api/orders/${id}`)
        console.log(response)
        return response.data
    } catch(error: any) {
        if(error.response?.status === 404) {
            return undefined
        }
        checkSessionExpired(error)
        throw error
    }
}

const getAvailabilityAdjustmentsListViewSummary = async (orderId: string, pagination: CSTableServerPagination):Promise<EntityListResponseItemAvailabilityAdjustmentViewSummary> =>{
    try {
        const { _pageIndex, _pageSize, _sort } = pagination
        const sortParsed = parseSort(_sort, ["date","item.sku","shop_id","qty","successful","message"])
        const params = {
            _pageIndex, 
            _pageSize,
            _sort: sortParsed,
            orderId
        }
        const response = await axios.get(`api/orders/availability-adjustments`, {
            params
        })
        return response.data
    } catch(error:any) {
        throw error
    }
}

const uploadOrderDocument = async(data:FormData) =>{
    try {
        const response = await axios.post('api/documents/upload', data)
        return response.data
    } catch(error:any) {
        throw error
    }
}

const deleteOrderDocument = async(orderId:string, orderDocumentType:OrderDocumentTypes, url:string) =>{
    try {
        const response = await axios.post('api/documents/delete', {
            orderId, orderDocumentType, url
        })
        return response.data
    } catch(error:any) {
        throw error
    }
}

const printMergedDocuments = async(documents:{url:string, qty:number}[]) =>{
    try {
        const response = await axios.post('api/documents/PDFMerge', {documents}, {
            responseType: 'blob'
        })
        return response
    } catch(error:any) {
        throw error
    }
}

const saveOrderShippingInfo = async(orderId: string, shippingInfo: OrderShippingInfo) =>{
    try {
        const response = await axios.put(`api/orders/${orderId}/shippingInfo`, {shippingInfo})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const saveOrderBillingInfo = async(orderId: string, billingInfo: OrderBillingInfo) =>{
    try {
        const response = await axios.put(`api/orders/${orderId}/billingInfo`, {billingInfo})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const saveOrderPackagingInfo = async(orderId: string, packagingInfo: OrderPackagingInfoUpdateRequest):Promise<EntitySingleWithAdditionalInfoResponseOrderOrderAdditionalInfo> =>{
    try {
        const response = await axios.put(`api/orders/${orderId}/packagingInfo`, {packagingInfo})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const updateOrderStatus = async(orderId: string, status: string):Promise<string> =>{
    try {
        const response = await axios.put(`api/orders/${orderId}/update-status`, {status})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const getAvailabilityUpdatesByDateAndShop = async (orderId: string, pagination: CSTableServerPagination):Promise<EntityListResponseItemAvailabilityAdjustmentViewSummary> =>{
    try {
        const { _pageIndex, _pageSize, _sort } = pagination
        const sortParsed = parseSort(_sort, ["date","item.sku"])
        const params = {
            _pageIndex, 
            _pageSize,
            _sort: sortParsed,
            orderId
        }
        const response = await axios.get(`api/orders/availability-updates`, {
            params
        })
        return response.data
    } catch(error:any) {
        throw error
    }
}

const patchOrderAdditionalInfo = async (orderId: string, additional_info: {[key: string]: unknown}):Promise<EntitySingleWithAdditionalInfoResponseOrderOrderAdditionalInfo> =>{
    try {
        const response = await axios.patch(`api/orders/${orderId}/additionalInfo`, {additional_info})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const getOrderMetadata = async (id:string) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseOrderMetadata,any> = await axios.get(`api/orders/${id}/metadata`)
        return response.data
    } catch(error: any) {
        if(error.response?.status === 404) {
            return undefined
        }
        checkSessionExpired(error)
        throw error
    }
}

const confirmCettireOrderItem = async(whId: string, order: Order, orderItem: OrderItem) =>{
    try {
        const {_id, shop_id, shop_order_id} = order
        if(!shop_id?.$oid || !shop_order_id) throw new Error("Invalid order")
        const response = await axios.patch(`api/orders/${shop_id?.$oid!}/cettire/${shop_order_id!}/confirm-order-item`, {orderItem, orderId: _id?.$oid!, whId})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const updateOrderItemVariation = async(orderId: string, sku: string, variation: string, allSiblings: boolean)=>{
    try {
        const response = await axios.patch(`api/orders/${orderId}/update-variation`, {orderId, sku, variation, allSiblings})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

export { 
    getOrders,
    getCustomerOrders,
    getOrder,
    getAvailabilityAdjustmentsListViewSummary,
    uploadOrderDocument,
    deleteOrderDocument,
    printMergedDocuments,
    saveOrderShippingInfo,
    saveOrderBillingInfo,
    getAvailabilityUpdatesByDateAndShop,
    patchOrderAdditionalInfo,
    saveOrderPackagingInfo,
    getOrderMetadata,
    confirmCettireOrderItem,
    updateOrderStatus,
    updateOrderItemVariation
}
