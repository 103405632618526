const defaultSortFunction = (a:any, b:any, property?:string):number=>{
    let aProperty = (property)?a[property]:a
    let bProperty = (property)?b[property]:a

    if(typeof aProperty === "string") {
        aProperty = aProperty.toLowerCase()
    }
    if(typeof bProperty === "string") {
        bProperty = bProperty.toLowerCase()
    }

    if ( aProperty < bProperty ){
        return -1;
    }
    if ( aProperty > bProperty ){
    return 1;
    }
    return 0;
}

/**
 * Return the portion of the array as it was paginated
 * @param array the array to be paginated
 * @param page_size the size of the page to be visualized
 * @param page_number the page of the array to be visualized 
 * @returns the array paginated if exists, undefined otherwise
 */
const paginate = (array:Array<unknown>|undefined, page_size:number|undefined, page_number:number):Array<unknown>|undefined=> {
    if(page_size === undefined) {
        return array
    }
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
}

const sort = (array: Array<unknown>, field: string, sortedDir: "ASC"|"DESC", sortFunction=defaultSortFunction):Array<unknown>=>{

    const result = array.sort((a,b)=>sortFunction(a,b,field))
    if(sortedDir==="DESC") {
        return result.reverse()
    }

    return result
}

/**
 * NB: use with array.filter() function as iterator
 * @param value 
 * @param index 
 * @param self 
 * @returns 
 */
const distinct = (value:unknown, index:number, self:unknown[])=>self.indexOf(value)===index

function groupBy<T>(array: Array<T>, property: keyof T) {
    return array.reduce((prev:{[key:string]: Array<T> }, curr:T) => {
        const groupKey = curr[property] as unknown as string
        (prev[groupKey] = prev[groupKey] || []).push(curr);
        return prev;
    }, {});
}

export { paginate, sort, distinct, groupBy }