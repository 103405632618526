import { getModules } from "@api/Modules"
import { Module } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { getLocalStorageItem, setLocalStorageItem } from "./LocalStorage"

export enum ModuleIdEnum {
    Core= 'CORE',
    BusinessConditions= 'BusinessConditions',
    B2B= 'B2B',
    BeCommerce= 'BeCommerce',
    Pos= 'POS',
    Erp= 'ERP',
    Finance= 'Finance',
    FxFf= 'FxFF',
    Warehouses= 'Warehouses',
    NetSuite= 'NetSuite',
    Test= 'Test',
    MarketplaceSavait= 'MarketplaceSavait'
}

export interface GenericModule extends Module {
    [key:string]: unknown
}

export const getUserModules = async (options?: {forceRefresh:boolean})=>{
    const { forceRefresh=false } = options ?? {}
    let userModules = getLocalStorageItem('user.modules') as GenericModule[]
    if(forceRefresh || !userModules) {
        // fetch shops from server
        const userModuleResponse = await getModules()
        userModules = (userModuleResponse.content ?? []) as GenericModule[]
        setLocalStorageItem('user.modules', userModules)
    }

    return userModules
}

export type ModuleDescriptor = {
    _id: ModuleIdEnum,
    title: string,
    description: string
}

export const moduleDescriptors: ModuleDescriptor[] = [
    {_id: ModuleIdEnum.BusinessConditions, title: Localization.PAGE_MODULES.TITLES.BUSINESS_CONDITIONS, description: Localization.PAGE_MODULES.DESCRIPTIONS.BUSINESS_CONDITIONS },
    {_id: ModuleIdEnum.B2B, title: Localization.PAGE_MODULES.TITLES.B2B, description: Localization.PAGE_MODULES.DESCRIPTIONS.B2B },
    {_id: ModuleIdEnum.Pos, title: Localization.PAGE_MODULES.TITLES.POS, description: Localization.PAGE_MODULES.DESCRIPTIONS.POS },
    {_id: ModuleIdEnum.Erp, title: Localization.PAGE_MODULES.TITLES.ERP, description: Localization.PAGE_MODULES.DESCRIPTIONS.ERP },
    {_id: ModuleIdEnum.Finance, title: Localization.PAGE_MODULES.TITLES.FINANCE, description: Localization.PAGE_MODULES.DESCRIPTIONS.FINANCE },
    {_id: ModuleIdEnum.FxFf, title: Localization.PAGE_MODULES.TITLES.FxFF, description: Localization.PAGE_MODULES.DESCRIPTIONS.FxFF },
    {_id: ModuleIdEnum.Warehouses, title: Localization.PAGE_MODULES.TITLES.WAREHOUSES, description: Localization.PAGE_MODULES.DESCRIPTIONS.WAREHOUSES },
    {_id: ModuleIdEnum.NetSuite, title: Localization.PAGE_MODULES.TITLES.NETSUITE, description: Localization.PAGE_MODULES.DESCRIPTIONS.NETSUITE },
    {_id: ModuleIdEnum.MarketplaceSavait, title: Localization.PAGE_MODULES.TITLES.MARKETPLACE_SAVAIT, description: Localization.PAGE_MODULES.DESCRIPTIONS.MARKETPLACE_SAVAIT },

]