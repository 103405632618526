import CSReactSelect, { CSOption } from "@components/Form/CSReactSelect"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Customer } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { FormErrors } from "@utils/FormUtils"
import { PageStates } from "@utils/PageStateUtils"
import equal from 'deep-equal'

type Props = {
    customer: Customer
    formErrors: FormErrors
    pageState: PageStates
    onChange: (updatedCustomer: Customer)=>unknown
}

export const isBeCommerceChanged = (prev: Customer, curr: Customer) =>{
    const prev_payment_methods = prev.payment_methods ?? []
    const prev_shipping_methods = prev.shipping_methods ?? []
    const curr_payment_methods = curr.payment_methods ?? []
    const curr_shipping_methods = curr.shipping_methods ?? []

    if(prev_payment_methods.length !== curr_payment_methods.length || prev_shipping_methods.length !== curr_shipping_methods.length) return true

    const arePaymentMethodsChanged = !equal(prev_payment_methods, curr_payment_methods)
    const areShippingMethodsChanged = !equal(prev_shipping_methods, curr_shipping_methods)

    return (arePaymentMethodsChanged || areShippingMethodsChanged)
}

export const isbeCommerceError = (formErrors: FormErrors) =>{
    return ["payment_methods", "shipping_methods"].find(key=>Object.keys(formErrors).includes(key)) 
}

const CustomerBeCommerce = (props: Props) =>{
    const { customer, formErrors, pageState, onChange } = props
    const { payment_methods=[], shipping_methods=[] } = customer


    const paymentMethodOptions:CSOption[] = [
        { value: "000000000000000000000001", label: Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.BANK_TRANSFER },
        { value: "000000000000000000000002", label: Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.PAYPAL },
        { value: "000000000000000000000003", label: Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.CREDIT_CARD }
    ]

    const shippingMethodOptions:CSOption[] = [
        { value: "000000000000000000000001", label: Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.CUSTOMER },
        { value: "000000000000000000000002", label: Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.DHL_EXPRESS },
    ]

    const paymentMethodOptionsSelected:CSOption[] = payment_methods.map(payment_method=>{
        return paymentMethodOptions.find(paymentMethodOption=>paymentMethodOption.value === payment_method.$oid)
    }).filter(paymentMethodOption => paymentMethodOption !== undefined) as CSOption[] // cast needed because ts don't recognize array filter changes in undefined values

    const shippingMethodOptionsSelected:CSOption[] = shipping_methods.map(shipping_method=>{
        return shippingMethodOptions.find(shippingMethodOption=>shippingMethodOption.value === shipping_method.$oid)
    }).filter(shippingMethodOption => shippingMethodOption !== undefined) as CSOption[] // cast needed because ts don't recognize array filter changes in undefined values

    return <>
    <SectionGroup>
        <div className="row pt-0">
            <div className="col-md-12">
                <CSReactSelect
                    controlId="payment_methods"
                    label={`${Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.METODI_DI_PAGAMENTO_ABILITATI}`}
                    multi
                    options={paymentMethodOptions}
                    value={paymentMethodOptionsSelected}
                    defaultValue={paymentMethodOptionsSelected}
                    client
                    onChange={(options:CSOption[])=>{
                        console.log({options})
                        const updatedCustomer = {
                            ...customer,
                            payment_methods: options.map(option=>({$oid: option.value}))
                        }
                        return onChange(updatedCustomer)
                    }}
                    disabled={false}
                    hasError={pageState===PageStates.FORM_ERROR && !!formErrors.payment_methods}
                    errorText={formErrors.payment_methods}
                />
            </div>
            <div className="col-md-12">
                <CSReactSelect
                    controlId="shipping_methods"
                    label={`${Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.METODI_DI_SPEDIZIONE_ABILITATI}`}
                    multi
                    options={shippingMethodOptions}
                    value={shippingMethodOptionsSelected}
                    defaultValue={shippingMethodOptionsSelected}
                    client
                    onChange={(options:CSOption[])=>{
                        console.log({options})
                        const updatedCustomer = {
                            ...customer,
                            shipping_methods: options.map(option=>({$oid: option.value}))
                        }
                        return onChange(updatedCustomer)
                    }}
                    disabled={false}
                    hasError={pageState===PageStates.FORM_ERROR && !!formErrors.shipping_methods}
                    errorText={formErrors.shipping_methods}
                />
            </div>
        </div>
    </SectionGroup>
    </>
}

export default CustomerBeCommerce