import CSFlagsSelect from "@components/Form/CSFlagsSelect"
import CSInput from "@components/Form/CSInput"
import { CSOption } from "@components/Form/CSReactSelect"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Customer, CustomerBillingInfo as _CustomerBillingInfo } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { FormErrors } from "@utils/FormUtils"
import { PageStates } from "@utils/PageStateUtils"
import equal from 'deep-equal'

type Props = {
    customer: Customer
    isEditable: boolean
    formErrors: FormErrors
    pageState: PageStates
    onChange: (updatedCustomer: Customer)=>unknown
}

export const isBillingInfoChanged = (prev: _CustomerBillingInfo, curr: _CustomerBillingInfo) =>{
    return !equal({
        vat_number: prev.vat_number ?? "",
        fc: prev.fc ?? "",
        sdi_code: prev.sdi_code ?? "",
        country: prev.address?.country_code ?? "",
        address_line_1: prev.address?.address_lines?.[0] ?? "",
        address_line_2: prev.address?.address_lines?.[1] ?? "",
        city: prev.address?.city ?? "",
        postal_code: prev.address?.postal_code ?? "",
        province: prev.address?.province ?? "",
        province_code: prev.address?.province_code ?? ""
    }, {
        vat_number: curr.vat_number ?? "",
        fc: curr.fc ?? "",
        sdi_code: curr.sdi_code ?? "",
        country: curr.address?.country_code ?? "",
        address_line_1: curr.address?.address_lines?.[0] ?? "",
        address_line_2: curr.address?.address_lines?.[1] ?? "",
        city: curr.address?.city ?? "",
        postal_code: curr.address?.postal_code ?? "",
        province: curr.address?.province ?? "",
        province_code: curr.address?.province_code ?? ""
    })
}

export const isBillingInfoError = (formErrors: FormErrors) =>{
    return ["vat_number", "fc", "sdi_code", "country", "address_line_1", "address_line_2", "city", "postal_code", "province", "province_code"].find(key=>Object.keys(formErrors).includes(key))
}

const CustomerBillingInfo = (props: Props) =>{
    const { customer, isEditable, formErrors, pageState, onChange } = props
    const { billing_info={} } = customer
    const { vat_number, fc, sdi_code, address={} } = billing_info
    const { country_code, address_lines, city, postal_code, province, province_code } = address
    const address_1 = address_lines?.[0]
    const address_2 = address_lines?.[1]

    const changeValue = (value: any, field: string) =>{
        const updatedBillingInfo = {
            ...billing_info,
            [field]: value
        }
        const updatedCustomer = {
            ...customer,
            billing_info: updatedBillingInfo
        }

        return onChange(updatedCustomer)
    }

    const changeAddressValue = (value: any, field: string) =>{
        const updatedAddress = {
            ...address,
            [field]: value
        }

        const updatedBillingInfo = {
            ...billing_info,
            address: updatedAddress
        }
        const updatedCustomer = {
            ...customer,
            billing_info: updatedBillingInfo
        }

        return onChange(updatedCustomer)
    }

    const changeAddressLine = (value: string, index: number) =>{
        const updatedAddressLines = [...address_lines ?? []]
        updatedAddressLines[index] = value

        const updatedAddress = {
            ...address,
            address_lines: updatedAddressLines
        }

        const updatedBillingInfo = {
            ...billing_info,
            address: updatedAddress
        }
        const updatedCustomer = {
            ...customer,
            billing_info: updatedBillingInfo
        }

        return onChange(updatedCustomer)
    }

    const countryOptionSelected = country_code ? {
        value: country_code,
        label: Localization.COUNTRIES_CODE[country_code as keyof typeof Localization.COUNTRIES_CODE] 
    }: undefined

    return <>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.CODICI}>
            <div className="row pt-0">
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.PARTITA_IVA}`} type="text" controlId="vat_number" 
                            value={vat_number ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'vat_number')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.vat_number}
                            errorText={formErrors.vat_number}
                        />
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.SDI}`} type="text" controlId="sdi_code" 
                            value={sdi_code ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'sdi_code')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.sdi_code}
                            errorText={formErrors.sdi_code}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.CODICE_FISCALE}`} type="text" controlId="fc" 
                            value={fc ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'fc')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.fc}
                            errorText={formErrors.fc}
                        />
                    </div>
                </div>
            </div>
        </SectionGroup>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.INDIRIZZO}>
            <div className="row pt-0">
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSFlagsSelect
                            mandatory
                            controlId={`country_code`}
                            label={Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.NAZIONE}
                            mode="COUNTRY"
                            disabled={!isEditable}
                            className="mt-field"
                            value={countryOptionSelected}
                            onChange={(option: CSOption) =>{changeAddressValue(option.value.toUpperCase(), 'country_code')}}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.country_code}
                            errorText={formErrors.country_code}
                        /> 
                    </div>
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-md-24">
                    <div className="mt-field">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.INDIRIZZO_1}`} type="text" controlId="address_1" 
                            value={address_1 ?? ""}
                            onChange={(e)=>changeAddressLine(e.target.value, 0)} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.address_1}
                            errorText={formErrors.address_1}
                        />
                    </div>
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-md-24">
                    <div className="mt-field">
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.INDIRIZZO_2}`} type="text" controlId="address_2" 
                            value={address_2 ?? ""}
                            onChange={(e)=>changeAddressLine(e.target.value, 1)} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.address_2}
                            errorText={formErrors.address_2}
                        />
                    </div>
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.CITTA}`} type="text" controlId="city" 
                            value={city ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'city')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.city}
                            errorText={formErrors.city}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mt-field">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.CODICE_POSTALE}`} type="text" controlId="postal_code" 
                            value={postal_code ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'postal_code')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.postal_code}
                            errorText={formErrors.postal_code}
                        />
                    </div>
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.PROVINCIA}`} type="text" controlId="province" 
                            value={province ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'province')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.province}
                            errorText={formErrors.province}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mt-field">
                        <CSInput className="mt-field" mandatory label={`${Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.SIGLA_PROVINCIA}`} type="text" controlId="province_code" 
                            value={province_code ?? ""}
                            onChange={(e)=>changeAddressValue(e.target.value, 'province_code')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.province_code}
                            errorText={formErrors.province_code}
                        />
                    </div>
                </div>
            </div>
        </SectionGroup>
    </>
}

export default CustomerBillingInfo