import SideMenuItem from '@components/SideMenu/SideMenuItem'
import { useMatch } from 'react-router-dom'
import SideMenuGroup from '@components/SideMenu/SideMenuGroup'
import SideMenuGroupItem from '@components/SideMenu/SideMenuGroupItem'
import Localization from '@localization/Index'
import { useState } from 'react'
import { ModuleIdEnum } from '@utils/ModulesUtils'
import { UserRoleEnum } from '@interfaces/User'

const SideMenu = () =>{

    const menuMatch = {
        Home: !!useMatch("/"),
        Shops: !!useMatch("/shops"),
        CreateShopChannel: !!useMatch("/shops/new/channel/:cs_shop_id"),
        CreateShop: !!useMatch("/shops/new/:cs_shop_id"),
        EditShopChannel: !!useMatch("/shops/channel/:cs_shop_id"),
        EditShop: !!useMatch("/shops/:cs_shop_id/:shop_id"),
        ShopsWarehousesBindings: !!useMatch("/shops/warehousesbindings"),
        Catalog: !!useMatch("/catalog"),
        BindingsShops: !!useMatch("/listing/bindings"),
        BindingsAttributes: !!useMatch("/listing/bindings/:shop_id"),
        BindingsRule: !!useMatch("/listing/bindings/:shop_id/attribute/:attribute/rule/:rule_id"),
        Orders: !!useMatch("/orders"),
        Order: !!useMatch("/orders/:id"),
        Shipments: !!useMatch("/shipments"),
        AvailabilityUpdate: !!useMatch("/availabilityupdate"),
        Warehouses: !!useMatch("/warehouses"),
        WarehousesMovements: !!useMatch("/warehousesmovements"),
        Category: !!useMatch("/category"),
        Returns: !!useMatch("/returns"),
        Return: !!useMatch("/returns/:id"),
        BusinessConditions: !!useMatch("/businessconditions"),
        EditBusinessCondition: !!useMatch("/businessconditions/:id"),
        Packaging: !!useMatch("/packaging"),
        PackagingOrders: !!useMatch("/packaging/orders"),
        Reservations: !!useMatch("/reservations"),
        Queues: !!useMatch("/queues"),
        Queue: !!useMatch("/queues/:id"),
        QueueEntry: !!useMatch("/queues/:id/:entryId"),
        Customers: !! useMatch("/customers"),
        ListingExclusionRules: !!useMatch("/listing/exclusion-rules"),
        Modules: !!useMatch("/modules")
    }

    const groupMatch = {
        Catalog: menuMatch.Catalog || menuMatch.AvailabilityUpdate || menuMatch.Reservations,
        Shops: menuMatch.Shops || menuMatch.CreateShopChannel || menuMatch.CreateShop || menuMatch.EditShop || menuMatch.EditShopChannel || menuMatch.ShopsWarehousesBindings,
        Warehouses: menuMatch.Warehouses || menuMatch.WarehousesMovements,
        Listing: menuMatch.BindingsShops || menuMatch.BindingsAttributes || menuMatch.BindingsRule || menuMatch.ListingExclusionRules,
        Returns: menuMatch.Returns || menuMatch.Return,
        Prices: menuMatch.BusinessConditions || menuMatch.EditBusinessCondition,
        Packaging: menuMatch.Packaging || menuMatch.PackagingOrders
    }

    const singleMatch = {
        Home: menuMatch.Home,
        Catalog: menuMatch.Catalog,
        AvailabilityUpdate: menuMatch.AvailabilityUpdate,
        Category: menuMatch.Category,
        Orders: menuMatch.Orders || menuMatch.Order,
        Returns: menuMatch.Returns || menuMatch.Return,
        Shops: menuMatch.Shops || menuMatch.CreateShopChannel || menuMatch.CreateShop || menuMatch.EditShop || menuMatch.EditShopChannel,
        ShopsWarehousesBindings: menuMatch.ShopsWarehousesBindings,
        Shipments: menuMatch.Shipments,
        Warehouses: menuMatch.Warehouses,
        WarehousesMovements: menuMatch.WarehousesMovements,
        ValueBindings: menuMatch.BindingsShops || menuMatch.BindingsAttributes || menuMatch.BindingsRule,
        BusinessConditions: menuMatch.BusinessConditions || menuMatch.EditBusinessCondition,
        Packaging: menuMatch.Packaging,
        PackagingOrders: menuMatch.PackagingOrders,
        Reservations: menuMatch.Reservations,
        Queues: menuMatch.Queues || menuMatch.Queue || menuMatch.QueueEntry,
        ListingExclusionRules: menuMatch.ListingExclusionRules,
        Customeers: menuMatch.Customers,
        Modules: menuMatch.Modules //TODO: add single module pages
    }

    const currentGroupMatch = Object.entries(groupMatch).find(match=> match[1])?.[0]
    const currentSingleMatch = Object.entries(singleMatch).find(match=> match[1])?.[0]
    const [currentExpanded, setCurrentExpanded] = useState<string | undefined>(currentGroupMatch)

    const toggleExpanded = (match:string) =>{
        return setCurrentExpanded(prev=>{
            if(prev === match) return undefined
            return match
        })
    }

    return (<>
        <div className="d-flex flex-column position-fixed vh-100">
            <div style={{width: '280px', paddingLeft: '0rem', paddingRight: '.75rem'}}>
                <SideMenuGroup name={Localization.MENU.CATALOGO} icon="bi-upc-scan" selected={currentGroupMatch === "Catalog"} expanded={currentExpanded === "Catalog"} onExpand={()=>{toggleExpanded('Catalog')}}>
                    <SideMenuGroupItem name={Localization.MENU.LISTA_ARTICOLI} to="/catalog" selected={currentSingleMatch === 'Catalog'} />
                    <SideMenuGroupItem name={Localization.MENU.AGGIORNAMENTO_DISPONIBILITA} to="/availabilityupdate" selected={currentSingleMatch === "AvailabilityUpdate"} />
                    <SideMenuGroupItem modules={[ModuleIdEnum.Warehouses]} name={Localization.MENU.RISERVE} to="/reservations" selected={currentSingleMatch === "Reservations"} />
                </SideMenuGroup>
                <SideMenuItem name={Localization.MENU.CATEGORY} icon="bi-share" to="/category" selected={currentSingleMatch === "Category"} />
                <SideMenuGroup modules={[ModuleIdEnum.BusinessConditions]} name={Localization.MENU.GESTIONE_PREZZI} icon="bi-cash-stack" selected={currentGroupMatch === "Prices"} expanded={currentExpanded === "Prices"} onExpand={()=>{toggleExpanded('Prices')}}>
                    <SideMenuGroupItem modules={[ModuleIdEnum.BusinessConditions]} name={Localization.MENU.CONDIZIONI_COMMERCIALI} to="/businessconditions" selected={currentSingleMatch === "BusinessConditions"} />
                </SideMenuGroup>
                <SideMenuItem name={Localization.MENU.ORDINI} icon="bi-card-checklist" to="/orders" selected={currentSingleMatch === "Orders"} />
                <SideMenuGroup name={Localization.MENU.SHOPS} icon="bi-cart" selected={currentGroupMatch === "Shops"} expanded={currentExpanded === "Shops"} onExpand={()=>{toggleExpanded('Shops')}}>
                    <SideMenuGroupItem name={Localization.MENU.LISTA} to="/shops" selected={currentSingleMatch === "Shops"} />
                    <SideMenuGroupItem name={Localization.MENU.ASSOCIAZIONE_MAGAZZINI} to="/shops/warehousesbindings" selected={currentSingleMatch === "ShopsWarehousesBindings"} />
                </SideMenuGroup>
                <SideMenuItem name={Localization.MENU.SHIPMENTS} icon="bi-truck" to="/shipments" selected={currentSingleMatch === "Shipments"} />
                <SideMenuItem name={Localization.MENU.RETURNS} icon="bi-arrow-repeat" to="/returns" selected={currentSingleMatch === "Returns"} />
                <SideMenuGroup modules={[ModuleIdEnum.Warehouses]} name={Localization.MENU.MAGAZZINI} icon="bi-house" selected={currentGroupMatch === "Warehouses"} expanded={currentExpanded === "Warehouses"} onExpand={()=>{toggleExpanded('Warehouses')}}>
                    <SideMenuGroupItem name={Localization.MENU.LISTA} to="/warehouses" selected={currentSingleMatch === "Warehouses"} />
                    <SideMenuGroupItem name={Localization.MENU.WHS_MOVEMENTS} to="/warehousesmovements" selected={currentSingleMatch === "WarehousesMovements"} />
                </SideMenuGroup>
                <SideMenuItem name={Localization.MENU.QUEUES} to="/queues" icon="bi-justify-left" selected={currentSingleMatch === "Queues"} />
                <SideMenuGroup name={Localization.MENU.LISTING} icon="bi-link-45deg" selected={currentGroupMatch === "Listing"} expanded={currentExpanded === "Listing"} onExpand={()=>{toggleExpanded('Listing')}}>
                    <SideMenuGroupItem name={Localization.MENU.ASSOCIAZIONE_ATTRIBUTI} to="/listing/bindings" selected={currentSingleMatch === "ValueBindings"} />
                    <SideMenuGroupItem name={Localization.MENU.REGOLE_ESCLUSIONE} to="/listing/exclusion-rules" selected={currentSingleMatch === "ListingExclusionRules"} />
                </SideMenuGroup>
                <SideMenuItem name={Localization.MENU.CUSTOMERS} modules={[ModuleIdEnum.B2B]} to="/customers" icon="bi-people" selected={currentSingleMatch === "Customers"} />
                <SideMenuGroup name={Localization.MENU.PACKAGING} roles={[UserRoleEnum.PACKAGER, UserRoleEnum.SUPPORT]} icon="bi-box-seam" selected={currentGroupMatch === "Packaging"} expanded={currentExpanded === "Packaging"} onExpand={()=>{toggleExpanded('Packaging')}}>
                    <SideMenuGroupItem name={Localization.MENU.STAMPA_ETICHETTE} roles={[UserRoleEnum.PACKAGER, UserRoleEnum.SUPPORT]} to="/packaging" selected={currentSingleMatch === "Packaging"} />
                    <SideMenuGroupItem name={Localization.MENU.ORDINI} roles={[UserRoleEnum.PACKAGER, UserRoleEnum.SUPPORT]} to="/packaging/orders" selected={currentSingleMatch === "PackagingOrders"} />
                </SideMenuGroup>
                <SideMenuItem name={Localization.MENU.MODULI} to="/modules" roles={[UserRoleEnum.SUPPORT]} icon="bi-grid" selected={currentSingleMatch === "Modules"} />
            </div>
            <div className="cs-sidebar-footer d-flex justify-content-end">
                {`CloudStore - v. ${process.env.APP_VERSION}`}
            </div>
        </div>
        </>
    )
}

export default SideMenu