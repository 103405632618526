import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { Customer, CustomerShippingInfo as _CustomerShippingInfo } from "@csapi/provisioning"
import Localization from "@localization/Index"
import { FormErrors } from "@utils/FormUtils"
import { PageStates } from "@utils/PageStateUtils"
import { useState } from "react"
import CustomerShippingInfo from "./CustomerShippingInfo"
import EditCustomerShippingInfo from "./EditCustomerShippingInfo"
import equal from 'deep-equal'

type Props = {
    customer: Customer
    isEditable: boolean
    formErrors: FormErrors
    pageState: PageStates
    onChange: (updatedCustomer: Customer)=>unknown
}

export const areShippingInfosChanged = (prev: _CustomerShippingInfo[], curr: _CustomerShippingInfo[]) =>{
    if(prev.length !== curr.length) return true

    let changed = false
    let currentIndex = 0
    checkLoop:
    for(const shippingInfo of prev) {
        const currentShippingInfo = curr[currentIndex]
        if(!equal({
            alias: shippingInfo.alias ?? "",
            first_name: shippingInfo.first_name ?? "",
            last_name: shippingInfo.last_name ?? "",
            company_name: shippingInfo.company_name ?? "",
            is_default: shippingInfo.is_default,
            country: shippingInfo.address?.country_code ?? "",
            address_line_1: shippingInfo.address?.address_lines?.[0] ?? "",
            address_line_2: shippingInfo.address?.address_lines?.[1] ?? "",
            city: shippingInfo.address?.city ?? "",
            postal_code: shippingInfo.address?.postal_code ?? "",
            province: shippingInfo.address?.province ?? "",
            province_code: shippingInfo.address?.province_code ?? "",
            email: shippingInfo.contacts?.email ?? "",
            mobile: shippingInfo.contacts?.mobile ?? "",
            phone: shippingInfo.contacts?.phone ?? "",
        }, {
            alias: currentShippingInfo.alias ?? "",
            first_name: currentShippingInfo.first_name ?? "",
            last_name: currentShippingInfo.last_name ?? "",
            company_name: currentShippingInfo.company_name ?? "",
            is_default: currentShippingInfo.is_default,
            country: currentShippingInfo.address?.country_code ?? "",
            address_line_1: currentShippingInfo.address?.address_lines?.[0] ?? "",
            address_line_2: currentShippingInfo.address?.address_lines?.[1] ?? "",
            city: currentShippingInfo.address?.city ?? "",
            postal_code: currentShippingInfo.address?.postal_code ?? "",
            province: currentShippingInfo.address?.province ?? "",
            province_code: currentShippingInfo.address?.province_code ?? "",
            email: currentShippingInfo.contacts?.email ?? "",
            mobile: currentShippingInfo.contacts?.mobile ?? "",
            phone: currentShippingInfo.contacts?.phone ?? "",
        })) {
            changed = true
            break checkLoop
        }

        currentIndex++
        continue checkLoop
    }

    return changed
}

export const isShippingInfoError = (formErrors: FormErrors) =>{
    //TODO: we should check it?
    return false
}

const CustomerShippingInfos = (props: Props)=>{
    const { customer, isEditable, formErrors, pageState, onChange } = props
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const { shipping_infos=[] } = customer

    const updateShippingInfos = (index: number, shippingInfo?: _CustomerShippingInfo) =>{
        let updatedShippingInfos = customer.shipping_infos ?? []
        if(!shippingInfo && updatedShippingInfos?.[index]) {
            // delete
            updatedShippingInfos.splice(index, 1)
        }
        if(shippingInfo && updatedShippingInfos?.[index]) {
            // update
            updatedShippingInfos[index] = shippingInfo
        }
        if(shippingInfo && !updatedShippingInfos?.[index]) {
            // add
            updatedShippingInfos.push(shippingInfo)
        }

        const updatedCustomer = {
            ...customer,
            shipping_infos: updatedShippingInfos
        }

        return onChange(updatedCustomer)
    }

    const setDefault = (index: number) =>{
        const updatedShippingInfos = (customer.shipping_infos ?? []).map((shipping_info, currentIndex)=>{
            shipping_info.is_default = (currentIndex === index)
            return shipping_info
        })

        const updatedCustomer = {
            ...customer,
            shipping_infos: updatedShippingInfos
        }

        return onChange(updatedCustomer)

    }

    const openNewShippingInfo = ()=>{
        setIsEditModalOpen(true)
    }

    return <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.INDIRIZZI_DI_SPEDIZIONE}>
        {shipping_infos.length === 0 &&
        <SectionSubGroup className="mt-field">
            <div className="d-flex flex-column justify-content-start align-items-center">
                <i className="bi-info-circle font-4x text-primary"></i>
                <p>{Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.NESSUN_INDIRIZZO_PRESENTE}</p>
                {isEditable && <button type="button" onClick={()=>{}} className='m-1 btn btn-primary'>{Localization.INIZIA}</button>}
            </div>
        </SectionSubGroup>
        }
        {shipping_infos.length > 0 &&
            <div className="mt-field w-100 row row-cols-md-2 row-cols-sm-1">
                {shipping_infos.map((shipping_info, index)=>{
                    return <CustomerShippingInfo onSetDefault={()=>{setDefault(index)}} onChange={(shippingInfo)=>{updateShippingInfos(index, shippingInfo)}} isDeletable={!shipping_info.is_default && shipping_infos.length>1} key={`customer_shipping_info_${index}`} shippingInfo={shipping_info} isEditable={isEditable} index={index} />
                })}
                {isEditable && 
                    <div className="p-0">
                    <SectionSubGroup className="m-2 bg-primary-light">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-left align-items-center">
                                <div className="ms-3 d-flex flex-column justify-content-center align-items-left text-muted" style={{minHeight: '125px'}}>
                                    {Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.AGGIUNGI_INDIRIZZO}
                                </div>
                            </div>
                            <i role="button" className={`bi bi-plus-circle text-primary fs-4`} onClick={() => {openNewShippingInfo()}}></i>
                        </div>
                    </SectionSubGroup>
                </div>
                }
            </div>
        }
        {isEditModalOpen && <EditCustomerShippingInfo show={isEditModalOpen} onHide={()=>setIsEditModalOpen(false)} isEditable={isEditable} onUpdate={(updatedShippingInfo)=>{
            updateShippingInfos(shipping_infos.length, updatedShippingInfo)
        }} />}
    </SectionGroup>
}

export default CustomerShippingInfos