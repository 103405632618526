const en = {
    SHOP_GENERAL: {
        CAMPI_COMUNI:()=> <>
            In questa sezione si indicano alcune proprietà condivise a tutti i tipi di Shop. Le proprietà sono le seguenti:
            <li><strong>Nome</strong> (obbligatorio) - Il nome che desideri dare al tuo shop; dev'essere lungo tra i 3 e i 100 caratteri.</li>
            <li><strong>Customer code</strong> (obbligatorio) - E' il codice che identifica il tuo shop; dev'essere lungo tra i 3 e i 100 caratteri.</li>
            <li><strong>Default currency</strong> (facoltativo) - E' la currency di default per il tuo shop.</li>
        </>,
        FLUSSI_LISTING:()=> <>
            In questa sezione puoi abilitare o disabilitare i flussi e i tipi di listing disponibili a questo tipo di shop.
        </>,
        STRATEGIA_MAGAZZINI:()=> <>
            <p>In questa sezione è possibile scegliere se, relativamente alla <strong>strategia di gestione dei magazzini</strong>, il tuo shop usa la <strong>configurazione predefinita per il tuo account</strong> oppure ne usa una <strong>personalizzata.</strong></p>
            <p>Se scegli di usarne una <strong>personalizzata</strong>, potrai scegliere dal menù a tendina quale applicare al tuo shop.</p>
        </>
    },
    SHOP_24S: {
        TITLE: ()=> <> <img style={{width:'100px', height: '100px'}} className="me-2" src="/shops/24SShort.png"></img> Istruzioni di compilazione per il tipo di shop 24S</>,
        SECTION1: {
            CONTENT:()=> <>
                <p>Questa guida ti aiuterà a creare o modificare correttamente uno shop di tipo <strong>24S</strong>.</p>
            </>
        },
        SECTION2: {
            TITLE: ()=><>Campi obbligatori</>,
            CONTENT:()=> <>
                <p>I campi obbligatori per uno shop di tipo <strong>24S</strong> sono i seguenti:</p>
                <ul>
                    <li><strong>Nome</strong> - Il nome che desideri dare al tuo shop; dev'essere lungo tra i 3 e i 100 caratteri.</li>
                    <li><strong>Customer code</strong> - E' il codice che identifica il tuo shop; dev'essere lungo tra i 3 e i 100 caratteri.</li>
                    <li><strong>Access key</strong> - ...</li>
                    <li><strong>Secret key</strong> - ...</li>
                    <li><strong>Brand label</strong> - ...</li>
                    <li><strong>Seller label</strong> - ...</li>
                    <li><strong>Shipping point</strong> - ...</li>
                </ul>
            </>
        }
    },
    SHOP_WINKLESTRAAT: {
        X_API_KEY:()=> <p>Questa chiave verrà generata in fase di creazione, e sarà possibile copiarla da qui o dalla lista degli shops. E' possibile anche rigenerarlo tramite apposito pulsante presente nella parte destra del campo di testo. È usato da Winkelstraat per l’accesso ai Webhooks elencati di seguito.</p>,
        WEBHOOKS: {
            SHIPMENT:()=> <p>Questo endpoint sarà disponibile e copiabile dopo la creazione dello shop.</p>,
            SHIPMENT_TRACK:()=> <p>Questo endpoint sarà disponibile e copiabile dopo la creazione dello shop.</p>,
            RMA_ITEMS:()=> <p>Questo endpoint sarà disponibile e copiabile dopo la creazione dello shop.</p>
        }
    },
    SHOP_FARFETCH:{
        MERCHANT_CODE:()=><p>Merchant Code</p>,
        MERCHANT_CODE_INFO:()=><p>Changing this field must be agreed with the Farfetch Success Partner. Then contact CloudStore support.</p>
    }
}

export default en